<template>
    <ModalWrapper custom-close transparent ref="modal" #default="{close}" @close-modal="$emit('close')">
        <div class="user-identification-modal">
            <button class="user-identification-modal__close" @click="close">
                <AppIcon icon="close"/>
            </button>
            <div class="user-identification-modal__name">
                {{ $t("components.common.theClientIsNotIdentifiedInTheMyIdSystem") }}
            </div>
            <div class="user-identification-modal__description">
                {{ $t("components.common.inOrderToPerformActiveOperationsYouNeedToIdentifyTheClientInTheMyIdSystem") }}
            </div>
            <AppButton class="user-identification-modal__button" :theme="isSent ? 'blueish-b' : 'primary-dark'" @click="submit">
                {{ $t("components.common.identifyViaMyId") }}
            </AppButton>
            <div v-if="isSent" class="user-identification-modal__message">
                {{ $t("components.common.requestHasBeenSentYouCanResendVia") }} <b>00:47</b>
            </div>
        </div>
    </ModalWrapper>
</template>
<script>
import ModalWrapper from "../partials/ModalWrapper";
import AppButton from "../partials/AppButton";
import AppIcon from "../partials/AppIcon";
export default {
    name: 'UserIdentificationModal',
    components: {AppIcon, AppButton, ModalWrapper},
    data() {
        return {
            isSent: false
        }
    },
    methods: {
        submit() {
            this.$emit('submit');
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.user-identification-modal {
    padding: 38px 45px 42px;
    width: 500px;
    background: #FFFFFF;
    border-radius: 12px;
    &__name {
        padding-left: 18px;
        padding-right: 45px;
        margin-bottom: 34px;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        text-align: center;
    }
    &__description {
        margin-bottom: 52px;
        padding-right: 19px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        text-align: center;
        font-family: $font-secondary;
    }
    &__message {
        margin-top: 16px;
        padding-left: 41px;
        padding-right: 42px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        text-align: center;
        color: #717171;
        font-family: $font-secondary;
        b {
            font-weight: 600;
        }
    }
    &__button {
        width: 354px;
        margin-left: auto;
        margin-right: auto;
    }
    &__close {
        padding: 16px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        border: 0;
        svg {
            display: block;
            width: 24px;
            height: 24px;
        }
    }
}
</style>
